<template>
  <b-row>
    <b-col cols="12">
    </b-col>
    <b-col>
      <b-row class="border border-secondary rounded">
        <b-col><b>Tedarikçi</b></b-col>
        <b-col><b>Stok</b></b-col>
        <b-col><b>PSF</b></b-col>
        <b-col><b>İskonto</b></b-col>
        <b-col><b>Alış</b></b-col>
        <b-col>
          <b-button
              size="sm"
              variant="outline-primary"
              @click="supplierStok=!supplierStok"
          >Tümü
          </b-button>
        </b-col>
      </b-row>
      <b-row
          class="border border-secondary rounded"
          v-if="supplierProduct.stock>0 || supplierStok"
          v-for="(supplierProduct,index) in stock.product.supplier_products"
          :key="index"
      >
        <b-col>{{ supplierProduct.supplier.name }}</b-col>
        <b-col>{{ supplierProduct.stock }}</b-col>
        <b-col>{{ supplierProduct.market_price }}</b-col>
        <b-col>{{ supplierProduct.discount }}</b-col>
        <b-col>{{ supplierProduct.price }} {{ supplierProduct.currency }}</b-col>
        <b-col>
          <b-button
              size="sm"
              variant="outline-primary"
              @click="setPrice(supplierProduct)"
          >Eşitle
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <b-row class="border border-primary rounded">
        <b-col cols="2">PSF TL</b-col>
        <b-col cols="2">İskonto %</b-col>
        <b-col cols="2">KDV %</b-col>
        <b-col cols="2">Alış (KDV'siz) TL</b-col>
        <b-col cols="2">KDV DAHİL</b-col>
        <b-col cols="2">Alış (KDV'li) TL</b-col>
      </b-row>
      <b-row class="border border-primary rounded p-0">
        <b-col cols="2" class="p-0">
          <b-input
              size="sm"
              v-model="newData.market_price"
              @blur="marketpriceCalculate"
              :disabled="!newData.isVat"
          />
        </b-col>
        <b-col cols="2" class="p-0">
          <b-input
              size="sm"
              v-model="newData.discount"
              @blur="discountCalculate"
              :disabled="!newData.isVat"
          />
        </b-col>
        <b-col cols="2" class="p-0">
          <v-select
              size="sm"
              v-model="newData.vat"
              label="title" :options="[0,1,8,18]"
              @option:selected="vatCalculate"
              class="select-size-sm"
              :disabled="newData.isVat"/>
        </b-col>

        <b-col cols="2" class="p-0">
          <b-input size="sm" @blur="priceCalculate2" v-model="newData.vatPrice" :disabled="newData.isVat"/>
        </b-col>
        <b-col cols="2" class="p-0">
          <b-form-checkbox v-model="newData.isVat" name="check-button" switch inline @change="reVatPriceCalculate"/>
        </b-col>
        <b-col cols="2" class="p-0">
          <b-input size="sm" v-model="newData.price" @blur="PriceCalculate" :disabled="!newData.isVat"/>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="2">
      <b-button @click="saveStock" size="sm" variant="success">Onayla</b-button>
      <br>
      <span>{{ message }}</span>
    </b-col>
  </b-row>
</template>


<script>
import vSelect from "vue-select";

export default {
  name: "SupplierMatching",
  components: {vSelect},
  props: {
    stock: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    supplierStok: true,
    message: '',
    newData: {
      isVat: false,
      vat: 0,
      vatPrice: 0,
      market_price: 0,
      price: 0,
      discount: 0
    }
  }),
  methods: {
    saveStock() {
      this.show = true
      this.message = ''
      if (!this.newData.price) {
        this.show = false;
        this.message = 'Eksik Veri'
        return this.$swal({
          title: 'Eksik Veri',
          icon: 'error',
          text: 'Alış Fiyatı Giriniz'
        })
      }

      this.stock.price = this.newData.price;

      this.$store.dispatch('activeStock/updateActiveStock', this.stock)
          .then(res => {
            if (res.status == 'success') {
              this.stock.status = 2;
              this.stock.approver_user = res.approver_user;
              this.stock.m
              this.show = false
              this.message = res.message;
            }
          })
    },
    setPrice(supplierProduct) {
      this.newData.market_price = supplierProduct.market_price;
      if (supplierProduct.market_price > 0)
        this.newData.discount = (((supplierProduct.market_price - supplierProduct.price) / supplierProduct.market_price) * 100).toFixed(1);
      this.newData.price = supplierProduct.price;
    },
    marketpriceCalculate($event) {
      this.newData.price = ($event.target.value - ($event.target.value * this.newData.discount / 100)).toFixed(2)
    },
    discountCalculate($event) {
      this.newData.price = (this.newData.market_price - (this.newData.market_price * $event.target.value / 100)).toFixed(2)
    },
    vatCalculate(selectedOption) {
      if (this.newData.vatPrice > 0)
        this.newData.price = (parseFloat(this.newData.vatPrice) + ((parseFloat(this.newData.vatPrice) * parseInt(selectedOption)) / 100)).toFixed(2)
      if (this.newData.market_price > 0)
        this.newData.discount = (((this.newData.market_price - this.newData.price) / this.newData.market_price) * 100).toFixed(2);
    },
    PriceCalculate() {
      if (this.newData.market_price > 0)
        this.newData.iskonto = (((this.newData.market_price - $event.target.value) / this.newData.market_price) * 100).toFixed(2);
    },
    priceCalculate2($event) {
      this.newData.price = (parseFloat($event.target.value) + ((parseFloat($event.target.value) * parseInt(this.newData.vat)) / 100)).toFixed(2)
      if (this.newData.market_price > 0)
        this.newData.discount = (((this.newData.market_price - this.newData.price) / this.newData.market_price) * 100).toFixed(2);
    },
    reVatPriceCalculate() {
      this.newData.price = null
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>